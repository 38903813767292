export const stadiumNames = [
    'Hover Stadium Seating',
    'Foldable Rectractable Telescopic Seating',
    'Waterproof Backrest Seating',
    'Fire and UV Resistant Foldable Seating',
    'Wooden Foldable Telescopic Seating',
    'Vinyl Foldable Seating',
    'Arena Upholstered Indoor Seating',
    'UV Protected Stadium Seating',
    'Temporary Folding Chairs',
    'Cushioned Gravity Chairs',
    'Fabric/Leather Foldable Seating with Armrests',
    'Solid Wood Veneered Foldable Chairs',
    'Blow Molding Fixed Seating',
    'Premium Foldable Seating',
    'Leather Upholstered Seating',
    'International Standards Compliant Seating',
    'Deluxe Stadium Seating',
    'School Lunchroom Seating',
    'Durable Plastic Seating',
    'Sports Audience Seating',
    'Lightweight Stadium Seating',
    'Plastic Seating Outdoor Stand',
    'Bleacher Seating without Backrest',
    'Bleacher Seating with Backrest and Armrest',
    'Auditorium Retractable Seating',
    'Plastic Clamshell Stadium Seating',
    'Retractable Theatre Bleacher Seating',
    'Indoor Bleacher Seating',
    'Theatre Retractable Seating',
    'Foldable Seating with Writing Board and Cupholder',
    'Indoor Telescopic Seating',
    'Single Telescopic Seat',
    'Indoor Telescopic Seating with Backrest'
  ]

  export const stadiumIds = [
    'hover-stadium-seating',
    'foldable-rectractable-telescopic-seating',
    'waterproof-backrest-seating',
    'fire-and-uv-resistant-foldable-seating',
    'wooden-foldable-telescopic-seating',
    'vinyl-foldable-seating',
    'arena-upholstered-indoor-seating',
    'uv-protected-stadium-seating',
    'temporary-folding-chairs',
    'cushioned-gravity-chairs',
    'fabric-foldable-seating-with-armrests',
    'solid-wood-veneered-foldable-chairs',
    'blow-molding-fixed-seating',
    'premium-foldable-seating',
    'leather-upholstered-seating',
    'international-standards-compliant-seating',
    'deluxe-stadium-seating',
    'school-lunchroom-seating',
    'durable-plastic-seating',
    'sports-audience-seating',
    'lightweight-stadium-seating',
    'plastic-seating-outdoor-stand',
    'bleacher-seating-without-backrest',
    'bleacher-seating-with-backrest-and-armrest',
    'auditorium-retractable-seating',
    'plastic-clamshell-stadium-seating',
    'retractable-theatre-bleacher-seating',
    'indoor-bleacher-seating',
    'theatre-retractable-seating',
    'foldable-seating-with-writing-board-and-cupholder',
    'indoor-telescopic-seating',
    'single-telescopic-seat',
    'indoor-telescopic-seating-with-backrest'
  ]