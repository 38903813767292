import { styled } from "@material-ui/core";

export const ContactWrapper = styled('div')({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
    paddingRight: '8px',
    width: '100%',
    height: '140vh',
    
    "@media (min-width: 768px)": {
        height: 'calc(100vh - 64px)',
        flexDirection: 'row',
        paddingLeft: '128px',
        paddingRight: '128px',
    }
})

export const ContactInstructions = styled('div')({
    boxSizing: 'border-box',
    width: '100%',
    height: '28vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    "@media (min-width: 768px)": {
        height: '100%',
        width: '50%',
        paddingRight: '34px',
    }
})

export const ContactForm = styled('div')({
    marginTop: '5.68%', // temporary, move to main wrapper after adding icons
    boxSizing: 'border-box',
    background: '#121F33',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '48px',
    gap: '18px',

    width: '100%',
    height: '100vh',
    "@media (min-width: 768px)": {
        marginTop: '5%', // temporary, move to main wrapper after adding icons
        height: '90%',
        width: '50%',
        marginLeft: '34px',
    }

})

export const ContactHeading = styled('h1')({
    color: '#213759',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '30px',
    textAlign: 'center',
    "@media (min-width: 768px)": {
        fontSize: '48px',
        lineHeight: '58px',
        textAlign: 'left',
    }
})

export const ContactDescr = styled('p')({
    color: '#525659',
    fontSize: '16px',
    lineHeight: '18px',
    textAlign: 'center',
    marginTop: '18px',
    "@media (min-width: 768px)": {
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'left',
        marginTop: '28px',
    }
})

export const ContactInput = styled('input')({
    width: '80%',
    height: '9%',
    borderRadius: '8px',
    background: '#fff',
    paddingLeft: '18px',
    border: 'none',
})

export const ContactMessage = styled('textarea')({
    paddingLeft: '18px',
    paddingTop: '24px',
    width: '80%',
    height: '33%',
    borderRadius: '8px',
    background: '#fff'
})

export const SubmitMsgBtn = styled('button')({
    border: 'none',
    boxSizing: 'content-box',
    width: '80%',
    height: '9%',
    borderRadius: '8px',
    background: '#fff',
    paddingLeft: '9px',
    paddingRight: '9px',
    cursor: 'pointer',
    boxShadow: '2px 2px 2px rgba(255, 255, 255, 0.25)',
    fontWeight: '700',
    color: '#121F33',

})

