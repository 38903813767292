import React from 'react'
import { useParams } from 'react-router-dom'
import { Product } from './Desktop/Product'
import { parseInfo } from './utils'

const images = require.context('../../assets/stadiumImages', true);
const stadiumInfo = require('../../assets/stadiumInfoClean.json')

export const StadiumProduct = () => {
    const { stadiumId } = useParams()
    const info = stadiumInfo[stadiumId]
    const { altName } = info
    const { tagLine, materialsData, specsData, standards } = parseInfo(info)

    return (
            <Product
                image={images(`./${stadiumId}.jpg`)}
                altName={altName}
                tagLine={tagLine}
                materialsData={materialsData} 
                specsData={specsData}
                standards={standards}

            />

    )
}
