import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { HeadingWrapper, ProductInfoPhoneContentWrapper, ProductInfoPhoneHeading, ProductInfoPhoneImg, ProductInfoPhoneSubHeading, ProductInfoPhoneTagLine, ProductInfoPhoneWrapper } from './ProductInfoPhone.styles'
import linesGray from '../../../assets/lines gray.svg'

export const ProductInfoPhone = ({ image, altName, tagLine }) => {
    const navigate = useNavigate()
    const onEnquireClick = useCallback(() => {
        navigate('/contact')
    }, [navigate])

    return (
        <ProductInfoPhoneWrapper>
            <img style={{ position: 'absolute', top: '-22px', right: '0' }} src={linesGray} alt="design element" />
            <ProductInfoPhoneImg src={image} alt={altName} />
            <ProductInfoPhoneContentWrapper>
                <HeadingWrapper>
                    <ProductInfoPhoneSubHeading>Stadium Seating</ProductInfoPhoneSubHeading>
                    <ProductInfoPhoneHeading>{altName}</ProductInfoPhoneHeading>
                </HeadingWrapper>
                <ProductInfoPhoneTagLine>{tagLine}</ProductInfoPhoneTagLine>
                <button onClick={onEnquireClick} type="" style={{ width: '90%', height: '52px', background: '#fff', fontWeight: '700', fontSize: '16px', letterSpacing: '0.05em', color: '#121F33', borderRadius: '8px' }}>Enquire Now</button>
            </ProductInfoPhoneContentWrapper>
        </ProductInfoPhoneWrapper>
    )
}
