import { styled, Table } from "@material-ui/core";

export const ProductWrapper = styled('div')({
    // background: 'linear-gradient(90deg, #121F33 33%, #FFFF 0%)',
})

// Product Info styles

export const ProductInfoWrapper = styled('div')({
    display: 'none',
    "@media (min-width: 768px)": {
        display: 'flex',
        alignItems: 'center',
        padding: '84px 0 0 82px',
    }
})

export const ProductInfoHeader = styled('div')({
    background: '#121F33',
    width: 'calc(100% - 148px)',
    height: '420px',
    marginRight: '82px',
    padding: '0 48px 0 112px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '24px',
    color: '#f5f5f5'
})

export const ProductImgWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '420px',
    background: 'linear-gradient(90deg, #f5f5f5 50%, #121F33 50%)',
})

export const ProductInfoImg = styled('img')({
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: '2',
    width: '400px',
    height: '400px'
})

export const ProductHeading = styled('h1')({
    fontWeight: '700',
    fontSize: '36px',
})

export const ProductSubHeading = styled('h2')({
    fontWeight: '400',
    fontSize: '26px',
    lineHeight: '34px',
    fontStyle: 'italic'
})

export const ProductHeadingWrapper = styled('div')({
    padding: '4px 0 2px 16px',
    borderLeft: '2px solid #f5f5f5'
})

export const TagLine = styled('p')({
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
})

// Common SideBar Styles

export const SidebarHeading = styled('h2')({
    fontWeight: '700',
    fontSize: '40px',
    color: '#fff',
    textAlign: 'center'
})
export const SidebarHeadingWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '33%',
    height: '100%',
})
export const SidebarContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '24px',

    height: '100%',
    width: '85%',
    
    color: '#f5f5f5',
    "@media (min-width: 768px)": {
        width: '67%',

    }
})

export const SideInfoHeading = styled('h4')({
    fontSize: '16px',
    "@media (min-width: 768px)": {
        fontSize: '18px',
    }
})

export const SideInfoDescr = styled('p')({
    fontSize: '16px',
    textAlign: 'left',
    "@media (min-width: 768px)": {
        textAlign: 'right',
        marginTop: '8px',
    }
})

// Materials Styles
export const MaterialsWrapper = styled('div')({
    marginTop: '82px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
})


export const MaterialsDescr = styled('p')({
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '8px',
})

export const MaterialsHeading = styled('h4')({
    fontSize: '18px',
})

export const MaterialsGridWrapper = styled('div')({
    marginTop: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%'
})

export const MaterialsContentWrapper = styled('div')({
    display: 'grid',
    textAlign: 'center',
    gap: '24px',
    gridTemplateColumns: 'repeat(1, 100%)',
    "@media (min-width: 768px)": {
        justifyItems: 'center',
        gridTemplateColumns: 'repeat(2, 50%)',
    }
})



export const SpecsWrapper = styled('div')({
    marginTop: '82px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#121F33',
    padding: '28px 0 28px 0'
})


export const TableHeading = styled('h3')({
    fontSize: '32px',
    color: '#f5f5f5',
    textAlign: 'center'
})

export const TableWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px'
})

export const LeftImg = styled('img')({
    display: 'none',
    "@media (min-width: 768px)": {
        display: 'block',
        position: 'absolute', left: 0, marginTop: '-128px'
    }
})

export const StyledTable = styled(Table)({
    width: '100%',
    "@media (min-width: 768px)": {
        width: '60%'
    }

})