import { styled } from "@material-ui/core";
// ProductInfoPhone
export const ProductInfoPhoneWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 64px)',
    paddingTop: '62px',
    background: 'linear-gradient(180deg, #f5f5f5 30%, #121F33 0%)',
    color: '#f5f5f5',
    overflowX: 'hidden',
    "@media (min-width: 768px)": {
        display: 'none'
    }
})

export const ProductInfoPhoneImg = styled('img')({
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: '2',
    width: '300px',
    height: '300px'
})

export const ProductInfoPhoneHeading = styled('h1')({
    fontWeight: '700',
    fontSize: '24px',
})

export const ProductInfoPhoneSubHeading = styled('h2')({
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
    fontStyle: 'italic'
})

export const HeadingWrapper = styled('div')({
    boxSizing: 'border-box',
    marginTop: '28px',
    marginLeft: '24px',
    borderLeft: '2px solid #f5f5f5',
    paddingLeft: '8px',
    paddingRight: '12px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
})

export const ProductInfoPhoneTagLine = styled('p')({
    marginLeft: '24px',
    marginRight: '8px',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
})

export const ProductInfoPhoneContentWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40vh',
    paddingTop: '3vh'
})