export const arrToCommaSeparatedStr = (arr, sep = 'and') => {
    if (arr.length === 1) return arr[0]
    if (arr.length === 2) return `${arr[0]} ${sep} ${arr[1]}`
    return arr.slice(0, arr.length - 1).join(', ') + ', ' + sep + ' ' + arr[arr.length - 1]
}

export const getMaterials = (features, description) => {
    const { seatAndBackrest, support, beam } = description
    const { chair, bracket } = features
    const { material: chairMaterial } = chair
    const { material: bracketMaterial, surfaceTreatment } = bracket
    return {
        'Seat and Backrest':seatAndBackrest, 
        'Support': support, 
        'Beam':beam, 
        'Chair':chairMaterial, 
        'Bracket': bracketMaterial, 
        'Surface Treatment':surfaceTreatment
    }
}

export const getFeatures = (features, description) => {
    const { foldingMechanism } = description
    const { options, install } = features
    return {
        foldingMechanism, options, install
    }
}

export const getSpecs = (features) => {
    const size = features?.chair?.size
    const {Width, 
        Depth, 
        Height, 
        cc
    } = size
    return [
        {name: 'Width', value: Width}, 
        {name: 'Depth', value: Depth}, 
        {name: 'Height', value: Height}, 
        {name: 'cc', value: cc}
    ]
}

export const getStandards = (infoStandards, features) => {
    const { chair, bracket } = features
    const {strength, fire} = infoStandards
    return [
        {name: 'Strength', value: strength},
        {name: 'Fire', value: fire.join(', ')},
        {name: 'Chair', value: chair?.materialStandards},
        {name: 'Bracket', value: bracket?.materialStandards},
    ]

}

export const getProductTagLine = (altName, features, description) => {
    const { application } = features
    // const feature = getFeatures(features, description)
    const applicationList = arrToCommaSeparatedStr(application)
    // const installList = arrToCommaSeparatedStr(feature.install)
    // const optionsList = arrToCommaSeparatedStr(feature.options)

    const descr = `Our one-of-a-kind system helps you assemble and disassemble your venue in minutes. ${altName} is ideal for ${applicationList}.`
    // Folding mechanism is made of ${feature.foldingMechanism} Comes with additional options- ${optionsList}. Installation options include ${installList}.`
    return descr
}

export const parseInfo = (info) => {
    const { altName, features, description } = info
    const materialsData = getMaterials(features, description)
    const featuresData = getFeatures(features, description)
    const specsData = getSpecs(features)
    const tagLine = getProductTagLine(altName, features, description)
    const standards = getStandards(info.standards, features)
    return {materialsData, featuresData, specsData, tagLine, standards}
}