import React, { useState } from 'react'
import { AppBar, Toolbar, Menu, Button, MenuItem, Box } from "@material-ui/core"
import { Link } from 'react-router-dom'
import logo from '../assets/logo.svg'
import { WebLinks } from './Navbar.styles'
export const Navbar = () => {
    const options = ['Rectractable Seating', 'Stadium Seating', 'Bleachers']
    const routes = ['/retractable-seating', '/stadium-seating', '/bleachers']
    const [selected, setSelected] = useState(-1);
    const [anchor, setAnchor] = useState(null);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const onMenuItemClick = (event, index) => {
        setAnchor(null);
        setSelected(index);
    };

    const onContactButtonClick = () => {
        setSelected(-1)
    }

    return (
        <AppBar position='static' style={{ height: '64px' }}>
            <Toolbar style={{ height: '64px', background: '#121212', justifyContent: 'space-between' }}>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <img src={logo} alt='logo' height='40px'></img>
                </Link>

                <Box style={{ display: 'flex', gap: '16px' }}>
                    <Button
                        onClick={openMenu}
                        color='primary'
                        variant='contained'
                        style={{ display: 'none' }}
                    >
                        Products
                    </Button>

                    <Menu
                        open={Boolean(anchor)}
                        getContentAnchorEl={null}
                        anchorEl={anchor}
                        onClose={closeMenu}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        {options.map((item, index) =>
                            <MenuItem
                                key={index}
                                onClick={(event) => onMenuItemClick(event, index)}
                                selected={index === selected}
                                component={Link}
                                to={routes[index]}
                                disableRipple
                            >
                                {item}
                            </MenuItem>
                        )}

                    </Menu>
                    <WebLinks>
                    <Link to='/stadium-seating' style={{ textDecoration: 'none' }}>
                        <Button color='#8E99A2'
                            variant='contained'
                            onClick={onContactButtonClick}
                            style={{ fontWeight: '600', color: '#121F33' }}
                        >
                            Products
                        </Button>
                    </Link>
                    <Link to='/contact' style={{ textDecoration: 'none' }}>
                        <Button color='#8E99A2'
                            variant='contained'
                            onClick={onContactButtonClick}
                            style={{ fontWeight: '600', color: '#121F33' }}
                            >
                            Contact Us
                        </Button>
                    </Link>
                            </WebLinks>
                </Box>
            </Toolbar>
        </AppBar>
    )
}
