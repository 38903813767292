import React from 'react'
import { MaterialsContentWrapper, MaterialsDescr, MaterialsGridWrapper, MaterialsHeading, MaterialsWrapper, SidebarHeading, SidebarHeadingWrapper} from './ProductDesktop.style'

export const Materials = ({ materialsData }) => {
    return (
        <MaterialsWrapper>
            <SidebarHeadingWrapper>
                <SidebarHeading style={{ color: '#121F33' }}>Materials</SidebarHeading>
            </SidebarHeadingWrapper>
            <MaterialsGridWrapper>
                <MaterialsContentWrapper>
                    {Object.entries(materialsData).map(([key, val]) => (
                        !!val ?<div key={key}>
                            <MaterialsHeading>{key}</MaterialsHeading>
                            <MaterialsDescr>{val}</MaterialsDescr>
                        </div>: <></>
                    ))}
                </MaterialsContentWrapper>
            </MaterialsGridWrapper>
        </MaterialsWrapper>
    )
}
