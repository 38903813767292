import { styled } from "@material-ui/core";
import retractable from '../assets/retractable.jpg'

export const HomeImageDiv = styled('div')({
    width: '100%',
    height: 'calc(100vh - 64px)',
    background: `url(${retractable}) rgba(18, 31, 51, 0.68)`,
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'multiply',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

})

export const ViewProductsBtn = styled('button')({
    background: 'rgba(255, 255, 255, 0.2)',
    border: '1px solid #FFFFFF',
    borderRadius: '8px',
    color: '#fff',

    height: '48px',
    width: '80%',
    fontWeight: '600',
    fontSize: '18px',
    cursor: 'pointer',
    "@media (min-width: 768px)": {
        background: 'rgba(255, 255, 255, 0.1)',
        height: '62px',
        width: '40%',
        fontSize: '28px',
    }
})

export const Header = styled('h1')({
    fontStyle: 'Montserrat',
    fontWeight: '900',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    marginBottom: '64px',
    width: '80%',
    height: '8%',
    paddingLeft: '24px',
    paddingRight: '24px',
    fontSize: '34px',
    "@media (min-width: 768px)": {
        marginBottom: '0px',
        marginTop: '-128px',
        width: '100%',
        height: '16%',
        paddingLeft: '48px',
        paddingRight: '48px',
        fontSize: '48px',
    }
})

export const SubHeader = styled('h2')({
    fontSize: '24px',
    fontWeight: '700',
    color: '#121F33',
    letterSpacing: '0.02em',
    "@media (min-width: 768px)": {
        fontSize: '28px',
    }
})

export const TitleHeader = styled('h2')({
    fontSize: '32px',
    fontWeight: '700',
    color: '#121F33',
    "@media (min-width: 768px)": {
        fontSize: '32px',
    }
})

export const Description = styled('p')({
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.05em',
    color: '#121F33',
    
})

export const AboutContainer = styled('div')({
    width: '100%',
    minHeight: '60vh',
    paddingTop: '24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    display: 'flex',
    "@media (min-width: 768px)": {
        minHeight: '100vh',
    }
})

export const HeaderContainer = styled('div')({
    height: '22vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0 8px 0 8px',
    textAlign: 'center',
    "@media (min-width: 768px)": {
        padding: '0 0 12px 0',
        textAlign: 'left',
        width: '960px',

    }
})

export const RetractContainer = styled('div')({
    display: 'none',
    "@media (min-width: 768px)": {
        height: '600px',
        background: '#fff',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 320px)',
        gridTemplateRows: '100px repeat(2, 200px)',
        gridTemplateAreas: `"a b c"
                            "d e f"
                            "g g i"`,
        paddingBottom: '24px',
    }
})

export const Img1 = styled('img')({
    gridArea: 'a'
})

export const Img2 = styled('img')({
    gridArea: 'e'
})

export const Img3 = styled('img')({
    gridArea: 'i',
    alignSelf: 'center'
})

export const TextOne = styled('h3')({
    gridArea: 'd',
    fontSize: '80px'
})

export const TextTwo = styled('h3')({
    gridArea: 'g',
    fontSize: '80px',
    color: '#8E99A2',
})

export const Maximize = styled('strong')({
    color: '#121F33',
    fontStyle: 'italic',
    letterSpacing: '0.05em'
})

export const CaseStudyContainer = styled('div')({
    width: '100%',
    background: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    padding: '6%',
    boxSizing: 'border-box',
    height: '100vh',
    "@media (min-width: 768px)": {
        textAlign: 'left',
        justifyContent: 'space-between',
        height: '60vh',
        flexDirection: 'row',
    }
})

export const CaseStudyLeft = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '90%',
    height: '60%',
    "@media (min-width: 768px)": {
        width: '50%',

    }
})

export const IndiaGateImg = styled('img')({
    height: '184px',
    width: '318px',
    "@media (min-width: 768px)": {
        height: '320px',
        width: '508px'
    }
})

export const MobileRetractContainer = styled('div')({
    height: '100vh',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',


    "@media (min-width: 768px)": {
        display: 'none'
    }

})

export const MobileTextGray = styled('h3')({
    color: '#8E99A2',
    fontSize: '32px',
    textAlign: 'center'
})