import { styled } from "@material-ui/core";

export const ChairListWrapper = styled('div')({
    display: 'grid',
    padding: '16px 24px',
    gap: '24px',
    "@media (min-width: 768px)": {
        gridTemplateColumns: 'repeat(4, 1fr)',

    }
})

