import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { LeftImg, SidebarContentWrapper,  SideInfoDescr, SideInfoHeading, SpecsWrapper, StyledTable, TableHeading, TableWrapper } from './ProductDesktop.style'
import linesGrayLeft from '../../../assets/lines gray left.svg'

export const SpecsStandards = ({ specsData, standards }) => {
    return (
        <SpecsWrapper>
            <LeftImg src={linesGrayLeft} alt="design"/>
            <SidebarContentWrapper style={{gap: '72px'}}>
                {!!specsData.length && <TableWrapper>
                    <TableHeading>Specs</TableHeading>
                    <StyledTable  size="small" aria-label="specs table">
                        {specsData.map((row) => {
                            return (!!row.value? <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    <SideInfoHeading>{row.name}</SideInfoHeading>
                                </TableCell>
                                <TableCell style={{ fontFamily: 'Inter' }} align="right">
                                    <SideInfoDescr>
                                    {row.value}
                                    </SideInfoDescr>
                                    </TableCell>
                            </TableRow>: <></>)
                        })}
                    </StyledTable>
                </TableWrapper>}
                <TableWrapper>
                    <TableHeading>Standards</TableHeading>
                    <StyledTable size="small" aria-label="standards table" >
                        {standards.map((row) => {
                            return ( !!row.value? <TableRow key={row.name} >
                                <TableCell component="th" scope="row">
                                    <SideInfoHeading>{row.name}</SideInfoHeading>
                                </TableCell>
                                <TableCell style={{ fontFamily: 'Inter' }} align="right">
                                    <SideInfoDescr>
                                    {row.value}
                                    </SideInfoDescr>
                                    </TableCell>
                            </TableRow>: <></>)
                        })}
                    </StyledTable>
                </TableWrapper>
            </SidebarContentWrapper>
        </SpecsWrapper>
    )
}
