import React, { useCallback, useEffect, useState } from 'react'
import emailjs from '@emailjs/browser';
import { ContactDescr, ContactForm, ContactHeading, ContactInput, ContactInstructions, ContactMessage, ContactWrapper, SubmitMsgBtn } from './Contact.styles'
import { Spinner } from '../Spinner';
import { Alert } from '@mui/material';

const alertMsg = {
    'error': 'There was an error sending your message.',
    'success': 'Your message was successfully sent!. Someone will be in touch shortly.'
}

export const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)
    const [alertState, setAlertState] = useState('')

    useEffect(() => {
        if (alertState) {
            const timer = setTimeout(() => {
                setAlertState('');
            }, 5000);
            
            return () => clearTimeout(timer);
        }
      }, [alertState]);     

    const onSendClick = useCallback((e) => {
        if (!name || !phone || !msg || !email) {
            alert('Please enter the required fields to continue.')
            return
        }
        setShowSpinner(true)

        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, {
            name,
            email,
            phone,
            msg
        }, process.env.REACT_APP_PUBLIC_KEY)
            .then((res) => {
                setName('')
                setEmail('')
                setPhone('')
                setMsg('')
                setShowSpinner(false)
                setAlertState('success')
            })
            .catch((err) => {
                setShowSpinner(false)
                setAlertState('error')
                console.log(err)
            })
    }, [name, phone, msg, email])


    return (
        <>
            {!!alertState && <Alert style={{zIndex: 5, position: 'absolute', top: '64px', right: 0, left: 0}} severity={alertState}>{alertMsg[alertState]}</Alert>}
        <ContactWrapper>
            <Spinner showSpinner={showSpinner}/>
            <ContactInstructions>
                <ContactHeading>Get in touch with us</ContactHeading>
                <ContactDescr>Our team of experts can help you meet your project requirements. We can help you with custom projects, detailed specs, and material information. Send us an email to schedule an appointment today!</ContactDescr>
            </ContactInstructions>
            <ContactForm>
                <ContactInput type="text" name="name" value={name} placeholder='Name*' onChange={(e) => setName(e.target.value)} />
                <ContactInput type="text" name="name" value={email} placeholder='Email*' onChange={(e) => setEmail(e.target.value)} />
                <ContactInput type='tel' name="name" value={phone} placeholder='Phone Number*' onChange={(e) => setPhone(e.target.value)} />
                <ContactMessage placeholder='Your Message*' value={msg} onChange={(e) => setMsg(e.target.value)} />
                <SubmitMsgBtn onClick={onSendClick}>Send Message</SubmitMsgBtn>
            </ContactForm>
        </ContactWrapper>
        </>
    )
}
