import React from 'react'
import { CircularProgress } from '@material-ui/core';

export const Spinner = ({showSpinner}) => {
  return (
    <>
    {showSpinner && <div style={{position: 'fixed', left: 0, top: 0, right: 0, bottom: 0, zIndex: 5, background: 'rgba(255,255,255,.3)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress color='#ffffff' style={{zIndex: 6, position: 'relative'}}/>
        Sending...
    </div>}
    </>
  )
}
