import React from 'react'
import { Link } from 'react-router-dom'
import { CardActionArea, CardMedia, CardContent, Card } from '@material-ui/core'
import { ChairListWrapper } from './ChairList.styles'

export const ChairList = ({ title, names, nameIds, images, linkPrefix }) => {
    return (
        <div>
            <h1 style={{textAlign: 'center', padding: '32px 0 32px 0', fontWeight: '700', fontSize: '42px'}}>{title}</h1>
            <ChairListWrapper>
            {names.map((name, index) =>
                {
                    return <Link style={{textDecoration: 'none'}} key={name} to={`${linkPrefix}/${nameIds[index]}`}>
                    <Card>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                image={images(`./${nameIds[index]}.jpg`)}
                                alt={name}
                            />
                            <CardContent>
                        <h3 style={{height: '24px', textAlign: 'center'}}>{name}</h3>

                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Link>}
            )}
            </ChairListWrapper>
        </div>
    )
}
