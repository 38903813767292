import React from 'react'
import { Materials } from './Materials'
import { ProductWrapper } from './ProductDesktop.style'
import { ProductInfo } from './ProductInfo'
import { ProductInfoPhone } from './ProductInfoPhone'
import { SpecsStandards } from './SpecsStandards'

export const Product = ({image, altName, tagLine, materialsData, specsData, standards}) => {
  return (
    <ProductWrapper>
      <ProductInfoPhone
       image={image}
       altName={altName}
       tagLine={tagLine}
      />
        <ProductInfo
          image={image}
          altName={altName}
          tagLine={tagLine} 
        />
        <Materials
        materialsData={materialsData}
        />
        <SpecsStandards
        specsData={specsData}
        standards={standards}
        />
    </ProductWrapper>
  )
}
