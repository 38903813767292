import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navbar } from './components/Navbar';
import { Retractable } from './components/Retractable';
import { Stadium } from './components/Stadium';
import { Bleachers } from './components/Bleachers';
import { Contact } from './components/contact/Contact';
import { StadiumProduct } from './components/productInfo/StadiumProduct';
import { Home } from './components/Home';
import ScrollToTop from './components/ScrollToTop';
import { Footer } from './components/Footer';

function App() {
  return (
    <>
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route exact path='/retractable-seating' element={<Retractable/>}/>
          <Route exact path='/stadium-seating' element={<Stadium />}/>
          <Route exact path='/stadium-seating/:stadiumId' element={<StadiumProduct />}/>
          <Route exact path='/bleachers' element={<Bleachers />}/>
          <Route exact path='/contact' element={<Contact />}/>
        </Routes>
      <Footer/>  
      </Router>
    </div>
      </>
  );
}

export default App;
