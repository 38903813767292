import React from 'react'
import { Link } from 'react-router-dom'
import { FooterContainer, FooterTag, FooterText, LinksContainer } from './Footer.styled'
import startupIndia from '../assets/startupindia.png'

export const Footer = () => {
  return (
    <FooterContainer>
        <LinksContainer>
        <Link to='/' style={{textDecoration: 'none'}}>
            <FooterTag>&#x2022; About Us</FooterTag>
        </Link>
        <Link to='/contact' style={{textDecoration: 'none'}}>
            <FooterTag>&#x2022; Contact Us</FooterTag>
        </Link>
        <Link to='/stadium-seating' style={{textDecoration: 'none'}}>
            <FooterTag>&#x2022; Our Products</FooterTag>
        </Link>
        </LinksContainer>
        <LinksContainer>
        <FooterText>CIN: U28999DL2022PTC405185</FooterText>
        <FooterText>W-12/10 PHASE-III, DLF CITY, GURUGRAM Haryana-122002</FooterText>
        <img src={startupIndia} alt='startup india logo' height={50}/>
        </LinksContainer>
    </FooterContainer>
  )
}
