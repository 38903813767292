import { styled } from "@material-ui/core";

export const FooterContainer = styled('div')({
    background: '#121212',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '50vh',
    "@media (min-width: 768px)": {
        flexDirection: 'row',
        height: '30vh',
    }
})

export const LinksContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    "@media (min-width: 768px)": {
    }
})

export const FooterTag = styled('p')({
    color: '#ccc',
    fontSize: '14px',
    '&:hover': {
        color: '#f5f5f5'
    }
})

export const FooterText = styled('p')({
    color: '#ccc',
    fontSize: '14px',
    width: '212px',
    textAlign: 'center'
})

