import React, { useCallback } from 'react'
import { ProductHeading, ProductHeadingWrapper, ProductImgWrapper, ProductInfoHeader, ProductInfoImg, ProductInfoWrapper, ProductSubHeading, TagLine } from './ProductDesktop.style'
import { Button } from '@material-ui/core'
import linesGray from '../../../assets/lines gray.svg'
import { useNavigate } from 'react-router-dom'

export const ProductInfo = ({ image, altName, tagLine }) => {
  const navigate = useNavigate()
  const onEnquireClick = useCallback(() => {
    navigate('/contact')
  }, [navigate])

  return (
    <ProductInfoWrapper>
      <ProductImgWrapper>
        <ProductInfoImg src={image} alt={altName} />
      </ProductImgWrapper>
      <ProductInfoHeader>
      <ProductHeadingWrapper>
        <ProductSubHeading>Stadium Seating</ProductSubHeading>
        <ProductHeading>{altName}</ProductHeading>
      </ProductHeadingWrapper>
        <TagLine>{tagLine}</TagLine>
        <div className='centeredHorizontally'>
        <Button onClick={onEnquireClick} style={{ width: '40%', height: '52px', background: '#fff', fontWeight: '700', fontSize: '16px', letterSpacing: '0.05em', color: '#121F33', marginTop: '16px', marginRight: '64px' }}>Enquire now</Button>
        </div>
      </ProductInfoHeader>
      <img style={{position: 'absolute', top: '128px', right: '0'}} src={linesGray} alt="design element"/>
    </ProductInfoWrapper>
  )
}
