import React from 'react'
import { ChairList } from './ChairList'

export const Retractable = () => {
  return (
    <div>
      <ChairList
      title={'Rectractable Seating'}
      />
    </div>
  )
}
