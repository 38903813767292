import React, { useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import { AboutContainer,  Description, Header, HeaderContainer, HomeImageDiv, Img1, Img2, Img3, Maximize, MobileRetractContainer, MobileTextGray, RetractContainer, SubHeader, TextOne, TextTwo,  ViewProductsBtn } from './Home.styles'
import retract1 from '../assets/1-retract.jpg'
import retract2 from '../assets/2-retract.jpg'
import retract3 from '../assets/3-retract.jpg'
// import indiaGate from '../assets/india-gate-2.jpg'

export const Home = () => {
    const navigate = useNavigate()
    const onBtnClick = useCallback(() => {
        navigate('/stadium-seating')
    }, [navigate])

    return (
        <div >
            <HomeImageDiv>
                <Header>
                    Seating Solutions Customized For Your Venue
                </Header>
                <ViewProductsBtn type="" onClick={onBtnClick}>
                    View Our Products
                </ViewProductsBtn>
            </HomeImageDiv>
            <AboutContainer>
                <HeaderContainer>
                    <SubHeader>Who we are</SubHeader>
                    <Description>
                    Xtractpro Seating Pvt. Ltd. is a seating manufacturer, exporter, and trader based in India. As one of the few companies manufacturing 100% of their products in India, we deliver seating solutions for schools, sports stadiums, convention centers, and theaters. We offer the option of customizing seating based on project requirements. Our product line includes bleachers, telescopic seating, and retractable seating that meet international safety and quality standards.
                    </Description>
                </HeaderContainer>
                <RetractContainer>
                    <Img1 src={retract1} alt="description of retractable seating" width={338} height={343} />
                    <Img2 src={retract2} alt="description of retractable seating" width={338} height={343} />
                    <Img3 src={retract3} alt="description of retractable seating" width={338} height={343} />
                    <TextTwo><TextOne>We help</TextOne>
                        you <Maximize>maximize</Maximize> your floor space</TextTwo>
                </RetractContainer>
            </AboutContainer>
            <MobileRetractContainer>
                <MobileTextGray>We help you <Maximize>maximize</Maximize> your floor space</MobileTextGray>
                    <img src={retract1} alt="description of retractable seating" width={209} height={210} />
                    <img src={retract2} alt="description of retractable seating" width={209} height={210} />
                    <img src={retract3} alt="description of retractable seating" width={209} height={210} />
            </MobileRetractContainer>
            {/* <CaseStudyContainer>
                <CaseStudyLeft>
                    <SubHeader>Our Clients</SubHeader>
                    <TitleHeader>Central Public Works Department</TitleHeader>
                <Description>Our latest project included providing retractable seating for the Central Public Works Department. These custom seats were installed at Kartavya Path (India Gate).</Description>
                </CaseStudyLeft>
                <IndiaGateImg src={indiaGate} alt='India Gate' height={320} width={508}/>
            </CaseStudyContainer> */}
        </div>
    )
}
