import React from 'react'
import { stadiumIds, stadiumNames } from '../assets'
import { ChairList } from './ChairList'
const images = require.context('../assets/stadiumImages', true);
export const Stadium = () => {
  return (
    <div><ChairList
      title={'Stadium Seating'}
      names={stadiumNames}
      nameIds={stadiumIds}
      images={images}
      linkPrefix={'/stadium-seating'}
    /></div>
  )
}
